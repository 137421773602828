import React, { useEffect } from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import * as Icon from "react-feather"
import AOS from "aos"
import Logging from "../components/_App/logging"
import "aos/dist/aos.css"
import "../../node_modules/aos/dist/aos.css"

// Blog Images
import BlogImg1 from "../assets/images/blog-image/blog1.png"
import BlogImg2 from "../assets/images/blog-image/blog2.jpg"
import BlogImg3 from "../assets/images/blog-image/blog3.jpg"
import BlogImg4 from "../assets/images/blog-image/blog4.jpg"
import BlogImg5 from "../assets/images/blog-image/blog5.jpg"
import BlogImg6 from "../assets/images/blog-image/blog6.jpg"
import BlogImg7 from "../assets/images/blog-image/blog7.jpg"
import BlogImg8 from "../assets/images/blog-image/blog8.jpg"
import BlogImg9 from "../assets/images/blog-image/blog9.jpg"
import Zomedico from "../assets/images/portfolio/zomedico.png"

// Shape Images
import Shape2 from "../assets/images/shape2.svg"
import Shape3 from "../assets/images/shape3.svg"
import Shape4 from "../assets/images/shape4.svg"
import Shape1 from "../assets/images/shape1.png"

import Shape5 from "../assets/images/shape5.png"

// Modal
import { Button, Modal, Card } from "react-bootstrap"

const portfolioList = [
  {
    id: 1,
    name: "Roopayur",
    imageurl: BlogImg1,
    tech: "SHOPIFY,RAZORPAY,YUGABOT",
    desc:
      "Roopayur is the manufacturer and seller of the skin products for men and women. We design and develop their ecommerce web app in SHOPIFY which provides multiple features like Promocode, Payment gateway, Chatbot and Blogs. In this we integrated multiple plugins like YugaBot and Razorpay.",
    Keyfeature: "SHOPIFY-RAZORPAY-YUGABOT",
  },
  {
    id: 2,
    name: "Cumulus",
    imageurl: BlogImg2,
    tech: "ANGULAR,NODEJS,MYSQL ",
    desc:
      "Cumulus is the global association to serve art and design education and research. We design and develop web app to manage Collection of Artifacts.",
    Keyfeature:
      "Role based authentication systems-Dynamic form field-Subscription system",
  },
  {
    id: 3,
    name: "Yoptimise",
    imageurl: BlogImg7,
    tech: "ANGULAR,NODEJS,MONGODB",
    desc:
      "Yoptimise uses Data Science to help you learn, understand and optimise your health.",
    Keyfeature:
      " Multilogin i.e. email, Facebook, Google- Multigraph Dashboard- Notifications- Task Schedule- Overview weekly, monthly, yearly",
  },

  // {
  //   id: 4,
  //   name: "Terei Brothers",
  //   imageurl: BlogImg4,
  //   tech: "REACT,DOTNET,MONGODB",
  //   desc:
  //     "Terei Brother is a CRM based web apps which help to manage inventory of Industry products.",
  //   Keyfeature:
  //     "List/Grid view of products - Global Search to find Company and products - Role based authentication systems- Active/Inactive company, product and respective members in one click.",
  // },
  {
    id: 5,
    name: "SendMeBox",
    imageurl: BlogImg5,
    tech: "REACTNATIVE,ANGULAR,NODEJS",
    desc: "Send Me Box is an Multivendor Ecommerce web and mobile apps.   ",
    Keyfeature:
      "Bidding system - Multivendor - Multigraph Dashboard - Notifications",
  },
  {
    id: 6,
    name: "Zomedico",
    imageurl: BlogImg6,
    tech: "SHOPIFY,NODEJS",
    desc:
      "Zomedico is the manufacturer and seller of the Ayurvedic products. We design and develop their ecommerce web app in SHOPIFY. In this we implement logic to Identify products based on answer given by customer. ",
    Keyfeature: "SHOPIFY-RAZORPAY",
  },

  {
    id: 7,
    name: "Chie Restrobeingz",
    imageurl: BlogImg9,
    tech: "REACT,REACTNATIVE,LARAVEL",
    desc: "Chei is an Ecommerce web and mobile app.",
    Keyfeature:
      "Suport Multilanguage - Location Autodetect- Time Tracking(Manually).",
  },
  {
    id: 8,
    name: "Tribute Today",
    imageurl: BlogImg8,
    tech: "ANGULAR,DJANGO,MONGODB",
    desc: "Tribute Today is a multi module CRM based application.",
    Keyfeature:
      "Role based authentication systems- Donation Management system -Ecommerce-User Posts",
  },
  {
    id: 9,
    name: "Jain Digital",
    imageurl: BlogImg3,
    tech: "IONIC ,NODEJS ,MONGODB ",
    desc:
      "Jain Digital is an mobile(Android, IOS) and web application to manage Distributor and their respective Customer Setup Box.",
    Keyfeature:
      "Role based authentication systems - Account Management - Export functionality like Excel, PDF.",
  },
]
const Portfolio = ({ item: any }) => {
  useEffect(() => {
    setTimeout(() => { AOS.init({ duration: 1500 }) }, 0)
  }, [])
  const MyVerticallyCenteredModal = props => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", height: "100%" }}
      >
        <Modal.Header
          closeButton
          styleName={{ margin: ".0rem .0rem .0rem auto" }}
        >
          <Modal.Title
            id="contained-modal-title-center text-center"
            style={{ margin: "0 auto" }}
          >
            <h4>{props?.item?.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5
            style={{
              padding: "1.5rem 1rem 0rem 1rem",
              fontWeight: 200,
              fontSize: "16px",
            }}
          >
            <div className="d-flex justify-content-center">
              {props?.item?.tech.split(",").map(p => (
                <Button clButtonss="btn btn-primary" href="#" role="button">
                  {p}
                </Button>
              ))}
            </div>
            {props?.item?.desc} <br />
            <div className="py-2">
              {props?.item?.Keyfeature.split("-").map(p => (
                <div style={{ textDecoration: "underline" }}>
                  {p}
                  <br />
                </div>
              ))}
            </div>
          </h5>
        </Modal.Body>
      </Modal>
    )
  }
  const RenderModal = ({ item }) => {
    const [modalShow, setModalShow] = React.useState(false)

    return (
      <>
        <button
          className="read-more-btn"
          onClick={() => {
            setModalShow(true)
          }}
        >
          {" "}
          Read More <Icon.PlusCircle />
        </button>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          item={item}
        />
      </>
    )
  }

  return (
    <div>
      <Layout>
        <SEO title="Portfolio" />
        <Logging title={"Portfolio"} />
        <Navbar />
        <PageBanner pageTitle="Our Portfolio" />
        <div className="checkout-area ptb-80">
          <div className="container">
            <div className="row">
              {/* <img src={Zomedico} alt="Otter dancing with a fish" /> */}
              {portfolioList.map((i, ind) => (
                <>
                  <div className="col-lg-6 col-md-12">
                    <div
                      className="billing-details"
                      style={{ height: "780px" }}
                    >
                      <h2 className="title textname">{i.name}</h2>
                      <div className="bar"></div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <img src={i.imageurl} alt="shape" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <p className="textname">{i.desc}</p>
                        </div>

                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <p
                              className="textname"
                              style={{ fontSize: "1.2rem" }}
                            >
                              Technology
                            </p>
                            <div
                              className="textname"
                              style={{ marginTop: "-2%" }}
                            >
                              {i.tech.split(",").map(p => (
                                <button
                                  class="button"
                                  style={{ marginLeft: "3%", marginTop: "3%" }}
                                >
                                  {p}
                                </button>
                              ))}
                            </div>

                            <p
                              className="textname"
                              style={{ fontSize: "1.2rem", marginTop: "3%" }}
                            >
                              KeyFeature
                            </p>
                            <div
                              className="textname"
                              style={{ marginTop: "-2%" }}
                            >
                              {i.Keyfeature.split("-").map(k => (
                                <li
                                  style={{
                                    marginLeft: "3%",
                                    marginTop: "1%",
                                    display: "inline-block",
                                  }}
                                >
                                  <Icon.CheckCircle
                                    style={{ color: "#2d46b9", width: "18" }}
                                  />
                                  {k}
                                </li>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="shape1">
          <img src={Shape1} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
        <div className="shape3">
          <img src={Shape3} alt="shape" />
        </div>
        <div className="shape4">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape5">
          <img src={Shape5} alt="shape" />
        </div>
        <div className="shape6 rotateme">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape7">
          <img src={Shape4} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={Shape2} alt="shape" />
        </div>
      </Layout>
      <Footer />
    </div>
  )
}
export default Portfolio
